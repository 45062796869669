import React, { createContext, ReactNode, useEffect, useState } from "react";
import _ from 'lodash';
import moment from 'moment';

export type ChartContextInterface = {
  data: any[],
  csvData: any[],
  onAdd: (data: any) => void,
  onRemove: (item: any) => void,
  onClear: () => void,
}

export const ChartContext = createContext<ChartContextInterface | null>(null);

const ChartProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [data, setData] = useState<Array<any>>([]);
  const [csvData, setCsvData] = useState<Array<any>>([]);

  useEffect(() => {
    if (data.length === 0) {
      setCsvData([]);
    }
    else {
      const a = _.map(data, (o) => {
        var i = {
          "着発信日": o.item.page_type === 'compare' ? `${o.item.start_date} ~ ${o.item.end_date}` : moment(o.item.meeting_date).format('Y-MM-DD'),
          "業務コード": o.item.title || '-',
          "オペレーター名": o.item.operator_name || '-',
          // "顧客ID": "???",
          "外線番号": o.item.phone_number || '-',
          "項目名": o.item.status || '-',
          "operator_positive++": '-',
          "operator_positive+": '-',
          "operator_negative-": '-',
          "operator_negative--": '-',
          "operator_perception": '-',
          "operator_emotion": '-',
          "operator_action": '-',
          "customer_positive++": '-',
          "customer_positive+": '-',
          "customer_negative-": '-',
          "customer_negative--": '-',
          "customer_perception": '-',
          "customer_emotion": '-',
          "customer_action": '-',
        };

        if (o.item.page_type === 'single') {
          _.map(o.emotions, (o) => {
            const result = JSON.parse(o.ser_result);
            const graph_value: number[] = result.graph_value || [];
            const emotion_pea: string[] = result.emotion_pea || [];

            var positive5 = 0;
            var positive7 = 0;
            var negative1 = 0;
            var negative3 = 0;
            var other = 0;

            var perception = 0;
            var emotion = 0;
            var action = 0;
            var other2 = 0;

            _.map(graph_value, (o) => {
              if (o === 1) {
                negative1++;
              }
              else if (o === 3) {
                negative3++;
              }
              else if (o === 5) {
                positive5++;
              }
              else if (o === 7) {
                positive7++;
              }
              else {
                other++;
              }
            });

            _.map(emotion_pea, (o) => {
              if (o === 'p') {
                perception++;
              }
              else if (o === 'e') {
                emotion++;
              }
              else if (o === 'a') {
                action++;
              }
              else {
                other2++;
              }
            });

            if (o.audio_type === 'operator') {
              i['operator_positive++'] = `${(positive7 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['operator_positive+'] = `${(positive5 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['operator_negative-'] = `${(negative3 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['operator_negative--'] = `${(negative1 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['operator_perception'] = `${(perception * 100 / (emotion_pea.length - other2)).toFixed(1)}%`;
              i['operator_emotion'] = `${(emotion * 100 / (emotion_pea.length - other2)).toFixed(1)}%`;
              i['operator_action'] = `${(action * 100 / (emotion_pea.length - other2)).toFixed(1)}%`;
            }
            else {
              i['customer_positive++'] = `${(positive7 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['customer_positive+'] = `${(positive5 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['customer_negative-'] = `${(negative3 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['customer_negative--'] = `${(negative1 * 100 / (graph_value.length - other)).toFixed(1)}%`;
              i['customer_perception'] = `${(perception * 100 / (emotion_pea.length - other2)).toFixed(1)}%`;
              i['customer_emotion'] = `${(emotion * 100 / (emotion_pea.length - other2)).toFixed(1)}%`;
              i['customer_action'] = `${(action * 100 / (emotion_pea.length - other2)).toFixed(1)}%`;
            }
          });
        }
        else {
          const arr1 = _.groupBy(o.emotions, 'audio_type');
          _.map(arr1, (res) => {
            var positive5 = 0;
            var positive7 = 0;
            var negative1 = 0;
            var negative3 = 0;
            var other = 0;
            var total1 = 0;

            var perception = 0;
            var emotion = 0;
            var action = 0;
            var other2 = 0;
            var total2 = 0;

            _.map(res, (item) => {
              const result = JSON.parse(item.ser_result);
              const graph_value: number[] = result.graph_value || [];
              total1 += graph_value.length;

              _.map(graph_value, (o) => {
                if (o === 1) {
                  negative1++;
                }
                else if (o === 3) {
                  negative3++;
                }
                else if (o === 5) {
                  positive5++;
                }
                else if (o === 7) {
                  positive7++;
                }
                else {
                  other++;
                }
              });

              const emotion_pea: string[] = result.emotion_pea || [];
              total2 += emotion_pea.length;

              _.map(emotion_pea, (o) => {
                if (o === 'p') {
                  perception++;
                }
                else if (o === 'e') {
                  emotion++;
                }
                else if (o === 'a') {
                  action++;
                }
                else {
                  other2++;
                }
              });
            });

            if (res[0].audio_type === 'operator') {
              i['operator_positive++'] = `${(positive7 * 100 / (total1 - other)).toFixed(1)}%`;
              i['operator_positive+'] = `${(positive5 * 100 / (total1 - other)).toFixed(1)}%`;
              i['operator_negative-'] = `${(negative3 * 100 / (total1 - other)).toFixed(1)}%`;
              i['operator_negative--'] = `${(negative1 * 100 / (total1 - other)).toFixed(1)}%`;
              i['operator_perception'] = `${(perception * 100 / (total2 - other2)).toFixed(1)}%`;
              i['operator_emotion'] = `${(emotion * 100 / (total2 - other2)).toFixed(1)}%`;
              i['operator_action'] = `${(action * 100 / (total2 - other2)).toFixed(1)}%`;
            }
            else {
              i['customer_positive++'] = `${(positive7 * 100 / (total1 - other)).toFixed(1)}%`;
              i['customer_positive+'] = `${(positive5 * 100 / (total1 - other)).toFixed(1)}%`;
              i['customer_negative-'] = `${(negative3 * 100 / (total1 - other)).toFixed(1)}%`;
              i['customer_negative--'] = `${(negative1 * 100 / (total1 - other)).toFixed(1)}%`;
              i['customer_perception'] = `${(perception * 100 / (total2 - other2)).toFixed(1)}%`;
              i['customer_emotion'] = `${(emotion * 100 / (total2 - other2)).toFixed(1)}%`;
              i['customer_action'] = `${(action * 100 / (total2 - other2)).toFixed(1)}%`;
            }
          })
        }

        return i;
      });

      setCsvData(a);
    }
  }, [data]);

  const onAdd = (value: any) => {
    setData([...data, value]);
  }

  const onRemove = (item: any) => {
    if (item.page_type && item.page_type === 'compare') {
      setData(_.filter(data, (o) => o.item.search !== item.search));
    }
    else {
      setData(_.filter(data, (o) => o.item === item));
    }
  }

  const onClear = () => {
    setData([]);
  }

  return (
    <ChartContext.Provider value={{
      data,
      csvData,
      onAdd,
      onRemove,
      onClear,
    }}>
      {children}
    </ChartContext.Provider>
  );
}

export default ChartProvider;